import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Fade } from "react-reveal"
import styles from "./index.module.css"

export default () => {
  const img = useStaticQuery(graphql`
    query expertfintech {
      laptop: file(relativePath: { eq: "icons/laptop.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gym: file(relativePath: { eq: "icons/gym.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      terrace: file(relativePath: { eq: "icons/terrace.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      team: file(relativePath: { eq: "icons/team.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      working: file(relativePath: { eq: "icons/working.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      football: file(relativePath: { eq: "icons/football.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonus: file(relativePath: { eq: "icons/bonus.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      emi: file(relativePath: { eq: "icons/emi.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className={`container mt-20 px-2 flex justify-between  items-center mx-auto`}
    >
      <div className={"w-full"}>
        <h2 className=" mb-2 heading-center">
          Benefits<span className={"red"}>.</span>
        </h2>
        <div className={"flex flex-wrap md:flex-row flex-col justify-center"}>
          <Card img={img.laptop} text={"Choice of Laptop and Desktop Set-Up"} />
          <Card img={img.gym} text={"Onsite Gym with Free Membership"} />
          <Card img={img.terrace} text={"Rooftop Terrace and Bar"} />
          <Card img={img.team} text={"Quarterly Team Days"} />
          <Card
            img={img.working}
            text={"Flexible Working and Dress-down Culture"}
          />
          <Card img={img.football} text={"Tickets to Premier League games"} />
          <Card img={img.bonus} text={"Quarterly Bonuses"} />
          <Card img={img.emi} text={"EMI Scheme Access"} />
        </div>
      </div>
    </div>
  )
}

const Card = ({ img, text }) => {
  return (
    <div
      className={
        `${styles.cardFlex} flex p-3 m-2 bg-primaryLight rounded-sm grow items-center flex-col basis-1/3`
      }
    >
      <div className={"flex-1 flex justify-center"}>
        <Img
          className={"min-w-12 w-24 max-h-24 my-3"}
          loading={"eager"}
          fluid={img.childImageSharp.fluid}
        />
      </div>
      <div className={"md:text-lg text-center text-secondary font-medium"}>{text}</div>
    </div>
  )
}

Card.propTypes = {
  img: PropTypes.any,
  text: PropTypes.string,
}
