import React from "react"

export default () => {
  return (
    <>
      <div
        className={
          "container mx-auto pt-20 pb-20 lg:pb-4  p-8 flex justify-center items-center flex-col"
        }
      >
        <div className={"text-2xl md:text-4xl text-center mb-4 font-black"}>
          Remuneration<span className={"red"}>.</span>
        </div>
        <div className={"text-md md:text-xl text-center max-w-3xl"}>
          We pay competitive base-salaries plus bonuses for back-office team
          members
        </div>
        <div className={"text-md md:text-xl text-center max-w-3xl mt-3"}>
          Recruiters can earn up to 40% commission on each deal
        </div>

        <div className={"text-md md:text-xl text-center max-w-3xl mt-3"}>
          Interested in joining our team? Send your CV into{" "}
          <a style={{color: "#a1a1e1"}} href="mailto:alex@referment.com">alex@referment.com</a>{" "}
          and let's arrange a time to chat!
        </div>
        {/* <a
          href={"https://app.referment.com"}
          rel="noopener noreferrer"
          target="_blank"
          className={"btn mt-8"}
        >
          View Our Open Jobs
        </a> */}
      </div>
    </>
  )
}
