import React from "react"

export default () => {
  return (
    <>
      <div
        className={
          "container mx-auto min-h-screen pt-20 md:pt-8 pb-20 lg:pb-4  p-8 flex justify-center items-center flex-col"
        }
      >
        <div className={"text-3xl md:text-5xl text-center mb-4 font-black"}>
          Careers at Referment<span className={"red"}>.</span>
        </div>
        <div className={"text-md md:text-xl text-center max-w-3xl"}>
          Referment is on a mission to build the most efficient and effective
          recruitment business in the world.
          <br />
          <br />
          Unlike traditional recruitment firms we have a heavy focus on using
          data and marketing to help our recruitment team attract new business
          and place candidates at exceptional rates.
          <br />
          <br />
          Our investment in our back-office team means that you’re set up to
          deliver from day one! They manage your jobs and social media presence
          and our systems filter candidates to provide you with shortlists of
          great talent, letting you focus on delivering.
        </div>
      </div>
    </>
  )
}
