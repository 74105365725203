import React from "react"

export default () => {
  return (
    <>
      <div
        className={
          "bg-primaryLight mx-auto shadow-lg p-6 max-w-xl lg:px-12 px-8 rounded flex-1 m-6 flex-col flex text-center"
        }
      >
        <div className={"text-secondary mb-4 font-bold text-2xl md:text-3xl "}>
          Location
        </div>
        <div className={"text-light md:text-lg rounded"}>
          We're based in Vauxhall, London at a TOG office with some incredible
          amenities including an on-site gym with free membership, a fully
          stocked bar and roof terrace with views over the River and
          Westminster. There's also the free teas and coffees plus a kitchen on
          each floor along with plenty of private and open breakout spaces.
          <br /> <br /> We have a flexible working policy with most of our team
          working a few days from home each week and a dress-down culture too.
          We're open to speaking to talent from around the UK as long as they're
          open to working from our London office a few days a month.
          <br />
        </div>
      </div>
    </>
  )
}
